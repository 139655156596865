<template>
  <div>
    <div>
      <b-card>
        <b-card-title class="text-danger h2">Add Gallery Item</b-card-title>
        <b-form @submit.prevent class="pt-3">
          <validation-observer ref="GalleryValidation">
            <b-row>
              <b-col md="12" class="mb-1">
                <b-form-group label="Gallery Category">
                  <validation-Provider
                    name="Gallery Category"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      placeholder="Select Gallery Category"
                      v-model="selected"
                      :options="gallerycategories"
                      label="title"
                    ></v-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Gallery Image">
                  <validation-Provider
                    name="Gallery Image"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-file
                      v-model="file"
                      @change="handleFileChange"
                    ></b-form-file>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <!-- <b-col md="12" class="mb-1">
                <b-form-group label="Gallery Desctiption">
                  <validation-Provider
                    name="Gallery Desctiption"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Gallery Desctiption"
                      v-model="form.duration"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col> -->

              <b-col md="12" class="mb-1 text-center">
                <b-button
                  variant="primary"
                  @click="addGallery()"
                  block
                  type="submit"
                >
                  Submit To Gallery
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormRadio,
  BFormGroup,
  BInputGroup,
  BFormTextarea,
  BForm,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BFormFile,
  BCardText,
  BLink,
  BFormInput,
  BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import categoryApi from "@/Api/Modules/category";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import galleryApi from "@/Api/Modules/gallery";

export default {
  name: "AddPackage",
  components: {
    BCard,
    BFormRadio,
    BFormFile,
    BForm,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    vSelect,
    BButton,
    BCol,
    BBadge,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },
  data() {
    return {
      form: {},
      file: [],
      selected: {
        title: "Select a gallery type",
      },
      gallerycategories: [
        {
          title: "Places",
        },
        {
          title: "Events",
        },
        {
          title: "Culture",
        },
        {
          title: "Foods",
        },
        {
          title: "Cloths",
        },
        {
          title: "Hotels",
        },
      ],
    };
  },

  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        this.resizeImage(file);
      }
    },
    resizeImage(file) {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
      };

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set desired width and height

        canvas.width = 1280;
        canvas.height = 794;
        ctx.drawImage(img, 0, 0, 1280, 794);

        canvas.toBlob((blob) => {
          this.file = new File([blob], file.name, { type: file.type });
       
        }, file.type);
      };

      reader.readAsDataURL(file);
    },
    async addGallery() {
      if (await this.$refs.GalleryValidation.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });
        let form = new FormData();
        form.append("gallery_type", this.selected.title);
        form.append("image", this.file);
        await galleryApi
          .AddGalleryImage(form)
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
<style scoped></style>

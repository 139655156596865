<template>
    <div>
      <div class="pt-2"></div>
      <div class="text-right">
        <b-button variant="primary" v-b-toggle.sidebar-1>Add Gallery Image</b-button>
      </div>
      <div class="pt-3">
        <GalleryTable/>
      </div>
  
      <b-sidebar id="sidebar-1"  shadow right>
        <AddGallery/>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import GalleryTable from "./Components/GalleryTable.vue";
  import AddGallery from "./Components/AddGallery.vue";
  
  import { BButton, BSidebar } from "bootstrap-vue";
  
  export default {
    name: "packages",
    components: {
    GalleryTable,
      AddGallery,
      BButton,
      BSidebar,
    },
  };
  </script>
  
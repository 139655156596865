import { api } from "../index";
import notification from "@/ApiConstance/toast";

export default {
  async AllPackages() {
    return await api.get("/allpackages");
  },
  async AddPackage(payload) {
    return await api.post("/addpackage", payload).then((res) => {
      notification.toast("SuccessFully Added", "success");
      window.location.href = "/packages";
    });
  },
  async UpdatePackage(payload) {
    return await api.post("/updatepackage", payload).then((res) => {
      notification.toast("SuccessFully Updated", "success");
      window.location.href = "/packages";
    });
  },
  async DeletePackage(id) {
    return await api.delete(`/deletepackage/${id}`).then((res) => {
      notification.toast("SuccessFully Deleted", "success");
      window.location.href = "/packages";
    });
  },
};

<template>
  <div>
    <div>
      <b-card>
        <b-form @submit.prevent>
          <validation-observer ref="GalleryValidation">
            <b-row>
              <b-col md="12" class="mb-1">
                <b-form-group label="Package Image">
                  <validation-Provider name="Package Image" v-slot="{ errors }">
                    <img v-if="url" width="30%" :src="url" class="img-fluid" />
                    <img
                      v-else
                      width="30%"
                      :src="appUrl + '/' + form.image"
                      class="img-fluid"
                    />
                    <b-form-file
                      v-model="file"
                      @change="previewImage"
                    ></b-form-file>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1 text-center">
                <b-button
                  variant="primary"
                  @click="updateGallery()"
                  block
                  type="submit"
                >
                  Submit To Gallery
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormRadio,
  BFormGroup,
  BInputGroup,
  BFormTextarea,
  BForm,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BFormFile,
  BCardText,
  BLink,
  BFormInput,
  BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import categoryApi from "@/Api/Modules/category";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import packageApi from "@/Api/Modules/packages";
import { $themeConfig } from "@themeConfig";
import galleryApi from "@/Api/Modules/gallery";

export default {
  name: "AddPackage",
  components: {
    BCard,
    BFormRadio,
    BFormFile,
    BForm,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    vSelect,
    BButton,
    BCol,
    BBadge,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },
  data() {
    return {
      form: {},
      file: "",

      url: "",
    };
  },
  props: {
    updatingdata: Object,
  },
  created() {
    this.initializeData();
  },
  setup() {
    // App Name
    const { appUrl } = $themeConfig.app;
    return {
      appUrl,
    };
  },
  methods: {
    previewImage: function (event) {
      var input = event.target;
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      this.resizeImage(file);
    },
    resizeImage(file) {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
        this.url = e.target.result;
      };

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set desired width and height

        canvas.width = 1280;
        canvas.height = 794;
        ctx.drawImage(img, 0, 0, 1280, 794);

        canvas.toBlob((blob) => {
          this.file = new File([blob], file.name, { type: file.type });
        }, file.type);
      };

      reader.readAsDataURL(file);
    },
    initializeData() {
      this.form = this.updatingdata;
      this.selected.title = this.updatingdata.gallery_type;
    },
    async updateGallery() {
      if (await this.$refs.GalleryValidation.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });
        let form = new FormData();

        if (this.file != "") {
          form.append("image", this.file);
        } else {
          form.append("image_url", this.form.image);
        }
        form.append("id", this.form.id);
        await galleryApi
          .UpdateGalleryImage(form)
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
<style scoped></style>
